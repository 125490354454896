''/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import { storeInfoPT, locationPhonePT, userInfoPT, addressPT, promoBarPT, carouselPT, welcomePromoPT }  from "../../config/prop-types";
import getRoutes from "../../config/routes";
import getFlowerRoutes from "../../config/flowerRoutes";
import getEverscoreRoutes from "../../config/everscoreRoutes";
import getEnjoyableRoutes from "../../config/enjoyableRoutes";
import getDeathRowRoutes from "../../config/deathRowRoutes";
import { connect } from "react-redux";
import cookie from "react-cookies";
import {
  globals,
  categoriesFunc,
  alertActions,
  homePageActions,
  locationActions,
  authActions,
  promoActions
} from '../../actions/index';
import HeaderMainContainer  from "../../components/HeaderMain/HeaderMainContainer";
import HeaderSimple from "../../components/HeaderSimple/HeaderSimple";
import HeaderSimpleBundle from "../../components/HeaderSimple/HeaderSimpleBundle";
import HeaderSimpleEverscore from "../../components/HeaderSimple/HeaderSimpleEverscore";
import HeaderSimpleFlower from "../../components/HeaderSimple/HeaderSimpleFlower";
import { history } from '../../helpers';
import * as toast from "../../components/utility/toast";
import FooterMain from "../../components/FooterMain/FooterMainContainer";
import FooterMainFlower from "../../components/FooterMainFlower/FooterMainContainerFlower";
import FooterMainBundle from "../../components/FooterMainBundle/FooterMainContainerBundle";
import FooterMainEverscore from "../../components/FooterMainEverscore/FooterMainEverscore";
import FooterMainEnjoyable from "../../components/FooterMainEnjoyable/FooterMainEnjoyable";
import ReactGA from 'react-ga4';
import { ga4Key, gtmKey, isKiosk, selectedTheme, storeName, storeDomain } from "../../config/constants";
import {socket} from "../../helpers/socketClient"
import TagManager from 'react-gtm-module';
import ConfirmationTimer from "../../components/ui/ConfirmationTimer";
import ModalSimple from "../../components/ModalSimple/ModalSimple";
import createActivityDetector from 'activity-detector';
import ConfirmationSimple from "../../components/ui/ConfirmationSimple";
import sendFirebaseToken from "../../helpers/sendFirebaseToken";
import SVGIcon from "../../components/utility/SVGIcon";
import MobileModal from "../../components/MobileModal/MobileModal";
import queryString from "query-string";
import Spinner from "../../components/Spinner/Spinner";
import HeroPromoContainer from "../../components/HeroPromo/HeroPromoContainer";
import HeroPromoAnimatedContainer from "../../components/HeroPromoAnimatedContainer/HeroPromoAnimatedContainer";
import HeaderSimpleEnjoyable from "../../components/HeaderSimple/HeaderSimpleEnjoyable";
import LogoDeathRow from "../../images/DeathRowLogo.png";
import VerifyModalAddress from "../../components/VerifyModalAddress/VerifyModalAddress";
import SimpleModalSnow from "../../components/ModalSimpleSnow/ModalSimpleSnow";
export const SocketContext = React.createContext(7);

const isEverscoreTheme = selectedTheme === "MultiTheme";
const isDeathRowTheme = selectedTheme === "DeathRowTheme";

const AppContainer = (props) => {
  const textAreaRef = useRef(null);
  const { dispatch, storeInfo, locationPhone, promoBar, carousel, user, address, welcomePromo, isBundleTheme } = props;
  const [socketState, setSocketState] = useState();
  const [kioskSessionModal, setKioskSessionModal] = useState(false);
  const [kioskSessionCloseModal, setKioskSessionCloseModal] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [verificationModal, setVerificationModal] = useState(false);
  // const [holidayModal, setHolidayModal] = useState(false);
  const [mobileWelcomeModal, setMobileWelcomeModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [showIosBanner, setShowIosBanner] = useState(false);
  const [qrScannerLoader, setQrScannerLoader] = useState(false);
  const loadInIos = cookie.load('HPlatform');
  const tagManagerArgs = {
    gtmId: gtmKey
  }

  const deliveryStatus = localStorage.getItem("deliveryStatus") &&
  JSON.parse(localStorage.getItem("deliveryStatus"));

  useEffect(() => {
    const timer = setCopySuccess && setTimeout(() => setCopySuccess (false), 3000);
    return () => clearTimeout(timer);
  }, [copySuccess]);
  useEffect(()=> {
    const params = queryString.parse(history.location.search);
    if(params && params.address && params.zip && params.lat && params.lng) {
      setQrScannerLoader(true)
      locationActions.locationSelect(params)
          .then(
              data => {
                if(data.status){
                  if(data.status !== 'notDelivery'){
                    localStorage.setItem('deliveryStatus', JSON.stringify(data.status));
                    localStorage.setItem('storeStatus', JSON.stringify(data.status));
                    locationActions.locationSelectInHeader(params)
                        .then(
                            (res) => {
                              localStorage.setItem('initial', true);
                              if(res.GUID){
                                localStorage.setItem(`guId_${storeDomain}`, JSON.stringify(res.GUID));
                              }
                              setQrScannerLoader(false);
                              history.push('/shop');
                              window.location.reload()
                            },
                            (err) => {
                              setQrScannerLoader(false)
                            }
                        )
                  }
                  }

                })
              }

  }, [])
  useEffect(() => {
    dispatch(globals.getSurfSide());
    !isBundleTheme && dispatch(homePageActions.getCarousel());
    !isBundleTheme && dispatch(homePageActions.getPromoBar());
    dispatch(locationActions.selectedLocation());
    if (
        deliveryStatus &&
        (deliveryStatus === "notDelivery" || deliveryStatus === "")
    ) {
      toast.error({
        text:
            "Please change your delivery address, delivery is not available in that location",
      });
    }
  }, []);
  useEffect(() => {
    if(initialLoad && address ){
      setInitialLoad(false)

      locationActions.locationSelect(address).then(
          data1 => {
            if (
                deliveryStatus &&
                deliveryStatus !== "notDelivery" && deliveryStatus !== ""
            ){
              if(data1.status === 'notDelivery'){
                toast.error({
                  text:
                      "Please change your delivery address, delivery is not available in that location",
                });
                localStorage.setItem('deliveryStatus', JSON.stringify(data1.status));
              }
            }
          }
      );
    }
  }, [ address ]);

  useEffect(() => {
    const addKlaviyoScript = () => {
      var script = document.createElement("script");
      script.setAttribute("type","text/javascript");
      script.setAttribute("async","true");
      script.setAttribute("src", `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${locationPhone?.klaviyo_meta?.COMPANY_ID}`)
      document.body.appendChild(script);
    };
    if(locationPhone?.klaviyo_meta?.COMPANY_ID) {
      addKlaviyoScript();
    };
  }, [locationPhone?.klaviyo_meta?.COMPANY_ID]);

  useEffect(() => {
    if(user && user.email) {

      // klaviyo login
      const _learnq =  window._learnq || [];
      if(selectedTheme === "MultiTheme") {
        _learnq.push(['identify', {
          '$email': user.email,
          "phone_number": user.phoneNumber,
          "MarketingAgreed" : user?.optInMessage,
          "reward_points": user?.points
        }]);
      } else {
        _learnq.push(['identify', {
          '$email': user?.email,
          "phone_number": user?.phoneNumber,
          "reward_points": user?.points
        }]);
      }
      // _learnq.identify(user.email, undefined, undefined, (data)=>console.log(23243234234, data));

      // klaviyo integration next sprint
      // const options = {
      //   method: 'POST',
      //   headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
      //   body: JSON.stringify({
      //     profiles: [
      //       {email: user.email},
      //       {phone_number: user.phoneNumber, sms_consent: true}
      //     ]
      //   })
      // };
      // fetch('https://a.klaviyo.com/api/v2/list/VEk8KP/subscribe?api_key=pk_4f00d211abdcc832068d76a620f579c748', options)
      //     .then(response => response.json())
      //     .then(response => console.log(response))
      //     .catch(err => console.error(err));
    }
    let t;
    if(user && !t && !socketState){
      const token = JSON.parse(localStorage.getItem('token'));
      t = socket(token)
      setSocketState(t)
      t.on(`${user && user.id}_order_updated`, (data) => {
        if(!history.location.pathname.includes("account/orders/")) {
          if (data.status === "Canceled") {
            toast.warning({text: `Your order is ${data.status}`})
          } else {
            toast.success({text: `Your order is ${data.status}`})
          }
        }
      });
    }
  }, [user, locationPhone]);

  useEffect(() => {
    dispatch(globals.storeInfo());
    dispatch(categoriesFunc.categoriesAll());
    dispatch(categoriesFunc.personalitiesAll());
    if (localStorage.getItem("token")) {
      dispatch(globals.userInfo());
    }
  }, [dispatch]);

  useEffect(() => {
    if(loadInIos && !isKiosk && !welcomePromo?.code && user && !localStorage.getItem("mobilePromo")) {
      dispatch(promoActions.getWelcomePromo());
    }
  }, [isKiosk, user, loadInIos]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    /* ReactGA.initialize(ga4Key); */

    const listen = history.listen(() => {
      const _learnq = window._learnq || [];
      const page = window.location.href;
      _learnq.push(['track', 'Viewed Page', {
        url: page
      }]);

      dispatch(alertActions.clear());
    });

    // Remove event listener on cleanup
    return () => {
      listen();
    };
  }, []);
  useEffect(()=> {
    const timerOut = 120000;
    const activityDetector = createActivityDetector({timeToIdle: timerOut, inactivityEvents: []});
    if(isKiosk && user && !kioskSessionModal &&  !history.location.pathname.includes("debit-processing") ) {
      activityDetector.on('idle', () => {
        setKioskSessionCloseModal(false)
        setKioskSessionModal(true)
      });
    }
    return ()=>activityDetector.stop();
  }, [isKiosk, user, kioskSessionModal]);

  useEffect(()=> {
    if(loadInIos && !isKiosk && user && welcomePromo?.code && !localStorage.getItem("mobilePromo") && !localStorage.getItem("mobileModalPromo") && !history.location.pathname.includes("debit-processing") ) {
        setMobileWelcomeModal(true)
    } else {
        setMobileWelcomeModal(false)
    }
  }, [isKiosk, user, welcomePromo, loadInIos]);

  const visibleHeader =
    history.location.pathname.includes("login") ||
    history.location.pathname.includes("forgot-password") ||
    history.location.pathname.includes("reset-password") ||
    history.location.pathname.includes("registration") ||
    history.location.pathname.includes("success") ||
    history.location.pathname.includes("confirmation") ||
    history.location.pathname.includes("failure") ||
    history.location.pathname.includes("checkout") ||
    history.location.pathname.includes("received-order") ||
    history.location.pathname.includes("page");
  const visibleFooter =
    history.location.pathname.includes("account") ||
    history.location.pathname.includes("faq") ||
    history.location.pathname.includes("contact") ||
    history.location.pathname.includes("about") ||
    history.location.pathname.includes("product") ||
    history.location.pathname.includes("cart") ||
    history.location.pathname.includes("promotions") ||
    history.location.pathname.includes("partnerships") ||
    history.location.pathname.includes("terms") ||
    history.location.pathname.includes("refund-return-policy") ||
    history.location.pathname.includes("shipping-policy") ||
    history.location.pathname.includes("privacy") ||
    history.location.pathname.includes("careers") ||
    history.location.pathname.includes("page");


  const visibleFooterMain =
    history.location.pathname === "/" ||
    history.location.pathname.includes("blog") ||
    history.location.pathname.includes("cannabis-delivery");

  const approveFunction = () => {
    setKioskSessionModal(false)
    dispatch(authActions.logOut())
  }

  // Get guId status for app from local storage
 
  useEffect(() => {

    const verificationStatus = localStorage.getItem("initial") && localStorage.getItem(`guId_${storeDomain}`);
    verificationStatus || history.location.pathname.includes("page") ?
      setVerificationModal(false)
      :
      setVerificationModal(true)
  }, []);
  // useEffect(() => {
  //   const holidayModal = localStorage.getItem("holiday");
  //   holidayModal?
  //     setHolidayModal(false)
  //     :
  //       setHolidayModal(true)
  // }, []);
  //
  // function handleChangeHoliday() {
  //   localStorage.setItem("holiday", "true")
  //   document.body.classList.remove("no-scroll")
  //   setHolidayModal(false)
  // }
  function handleChange() {
    setVerificationModal(false)
  }
  const closeModal = () => {
      setKioskSessionModal(false)
  }
  const closeSession = () => {
    setKioskSessionCloseModal(true)
  }
  const keepShopping = () => {
    setKioskSessionCloseModal(false)
  }
  const endSession = () => {
    setKioskSessionCloseModal(false)
    dispatch(authActions.logOut())
  }
  const closeMobileWelcomeModal = () => {
    setMobileWelcomeModal(false)
    localStorage.setItem("mobileModalPromo", "true")
  }
  useEffect(() => {
    sendFirebaseToken( dispatch )
  },[])

  // iOS Banner logic
  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  // Checks if should display install popup notification:
  if (!localStorage.getItem('IosBanner') && !showIosBanner && isIos() && !isInStandaloneMode()) {
    setShowIosBanner(true);
  }
  window.addEventListener('appinstalled', (evt) => {
    setShowIosBanner(false)
    localStorage.setItem('IosBanner', "close");
  });
  const closeIosBanner = (val) => {
    setShowIosBanner(val)
    localStorage.setItem('IosBanner', "close");
  }

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess(true);
  };

  const renderRoutes = () => {
    if (selectedTheme === "FlowerTheme") {
      return getFlowerRoutes(storeInfo)
    } else if (selectedTheme === "MultiTheme") {
      return getEverscoreRoutes(storeInfo)
    } else if (selectedTheme === "DeathRowTheme") {
      return getDeathRowRoutes(storeInfo)
    } else if (selectedTheme === "EnjoyableTheme") {
      return getEnjoyableRoutes()
    } else {
      return getRoutes(storeInfo)
    }
  };

  const renderFooter = () => {
    if (selectedTheme === "FlowerTheme") {
      return (
        <>
        {visibleFooter && (
          <FooterMainFlower
            simple
            license_number={locationPhone && locationPhone.license_number}
            store_name={storeInfo && storeInfo.name}
          />
        )}
        {visibleFooterMain && (
          <FooterMainFlower
            license_number={locationPhone && locationPhone.license_number}
            store_name={storeInfo && storeInfo.name}
          />
      )}
    </>
      )
    } else if (selectedTheme === "BundleTheme") {
      return (
        <>
            {visibleFooter && (
              <FooterMainBundle
                simple
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}
              />
            )}
            {visibleFooterMain && (
              <FooterMainBundle
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}
              />
          )}
        </>
        )
    }
    else if (selectedTheme === "EnjoyableTheme") {
      return (
        <>
            {visibleFooter && (
              <FooterMainEnjoyable
                simple
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}
              />
            )}
            {visibleFooterMain && (
              <FooterMainEnjoyable
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}
              />
          )}
        </>
        )
    } else if (selectedTheme === "MultiTheme" ) {
      return (
        <>
            {visibleFooter && (
              <FooterMainEverscore
                selectedTheme="MultiTheme"
                simple
                brandLogo={storeInfo && storeInfo.marketplace_logo}
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}
              />
            )}
            {visibleFooterMain && (
              <FooterMainEverscore
                selectedTheme="MultiTheme"
                brandLogo={storeInfo && storeInfo.marketplace_logo}
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}

              />
          )}
        </>
        )
    }
    else if (selectedTheme === "DeathRowTheme") {
      return (
        <>
            {visibleFooter && (
              <FooterMainEverscore
                selectedTheme="DeathRowTheme"
                simple
                brandLogo={LogoDeathRow}
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}
              />
            )}
            {visibleFooterMain && (
              <FooterMainEverscore
                selectedTheme="DeathRowTheme"
                brandLogo={LogoDeathRow}
                license_number={locationPhone && locationPhone.license_number}
                store_name={storeInfo && storeInfo.name}

              />
          )}
        </>
        )
    } else {
      return (
        <>
          {visibleFooter && (
            <FooterMain
              simple
              license_number={locationPhone && locationPhone.license_number}
              store_name={storeInfo && storeInfo.name}
            />
          )}
          {visibleFooterMain && (
            <FooterMain
              license_number={locationPhone && locationPhone.license_number}
              store_name={storeInfo && storeInfo.name}
            />
          )}
        </>
      )}
  };

  const renderHeader = () => {
    return <HeaderMainContainer
        brandName={storeName}
        brandLogo={storeInfo && storeInfo.marketplace_logo}
        sessionClose={closeSession}
    />
  };

  const renderHeaderSimple = () => {
    if (selectedTheme === "FlowerTheme") {
      return <HeaderSimpleFlower />
    } else if (selectedTheme === "BundleTheme") {
      return <HeaderSimpleBundle />
    } else if (selectedTheme === "MultiTheme" || selectedTheme === "DeathRowTheme" ) {
      return <HeaderSimpleEverscore />
    } else if (selectedTheme === "EnjoyableTheme") {
      return <HeaderSimpleEnjoyable />
    } else {
      return <HeaderSimple />
    }
  };

  return (
    <>
      {qrScannerLoader && <div className="loading-hide-page"><Spinner color='var(--color-brand)'/></div>}
      <SocketContext.Provider value={socketState}>
        {!visibleHeader ?
          <>
            {!isEverscoreTheme && !isDeathRowTheme  && (
              <>
                {promoBar && promoBar?.length ? <HeroPromoAnimatedContainer promoBar={promoBar}></HeroPromoAnimatedContainer> :
                <>
                  {carousel && carousel.length > 0 && carousel.slice(0, 1).map(item => <HeroPromoContainer copy={item.subtitle} key={item.subtitle} isEverscoreTheme={isEverscoreTheme}/>)}
                </>
                }
              </>)
            }
            {renderHeader()}
          </>
         :
         <>{renderHeaderSimple()}</>
        }

      {renderRoutes()}
      {renderFooter()}

      </SocketContext.Provider>

      <ModalSimple shown={kioskSessionModal} onChange={() => closeModal()} modalId="auto_signOut" className={kioskSessionModal ? 'visible_modal' : 'hidden_modal'}>
        {kioskSessionModal && <ConfirmationTimer
          headingText="HEY BUD, ARE YOU STILL THERE?"
          cancelButtonText="Keep Shopping"
          approveButtonText="Sign Out"
          approveFunction={() => approveFunction()}
          cancelFunction={() => closeModal()}
        />}
        </ModalSimple>
        <ModalSimple shown={kioskSessionCloseModal} onChange={() => keepShopping()} modalId="close_session" className={!kioskSessionModal && kioskSessionCloseModal ? 'visible_modal' : 'hidden_modal'}>
          <ConfirmationSimple
            headingText="RESET SESSION?  ARE YOU SURE?"
            cancelButtonText="Reset Session"
            approveButtonText="Keep Shopping"
            approveFunction={() => keepShopping()}
            cancelFunction={() => endSession()}
          />
        </ModalSimple>

        {verificationModal &&
            // <ModalVerification isShown={verificationModal} onChange={handleChange} brandLogo={storeInfo && storeInfo.marketplace_logo}/>
          <VerifyModalAddress isShown={verificationModal} onChange={handleChange} verifyModal={true} brandLogo={storeInfo && storeInfo.marketplace_logo} />
        }
      {/*{!verificationModal && holidayModal &&*/}
      {/*    <SimpleModalSnow shown={holidayModal && !verificationModal} onChange={handleChangeHoliday} />*/}
      {/*}*/}


        {mobileWelcomeModal &&
          <MobileModal shown={mobileWelcomeModal} onChange={()=>closeMobileWelcomeModal()} className="modal"  modalId="address-modal">
            <div className="modal__mobile">
              <h2 className={"modal__mobile--heading"}>enjoy 30% off</h2>
              <h3 className="modal__mobile--subHeading">hey bud,</h3>
            </div>
            <p className="modal__mobile--text">Welcome to the club. We'll teach you the handshake later, but for now, Enjoy 30% OFF</p>
            <div className='modForm'>
              <div className="modal__form">
                <button onClick={copyToClipboard} className="modal__button" >
                  {copySuccess ?
                    <span>Copied!</span>
                    :
                    <>
                      <span>{welcomePromo?.code}</span>
                      <SVGIcon name="copy" width={15}/>
                    </>
                  }
                </button>
                <form className="t-hidden">
                  <textarea ref={textAreaRef} value={welcomePromo?.code} />
                </form>
              </div>
            </div>
          </MobileModal>
        }

{/*     Hide until iOS app is ready
        !isBundleTheme &&
        <MediaQuery maxWidth={759}>
          <BannerIos shown={showIosBanner} onChange={closeIosBanner} data-testId="ios-banner" />
        </MediaQuery> */}

    </>
  );
};

const mapStateToProps = ({ storeInfo, locationInfo, homePageCarousel, homePagePromoBar, userInfo, userAddress, welcomePromo, landingInfo}) => ({
    storeInfo: storeInfo.storeInfo,
    locationPhone: locationInfo.locationPhone,
    carousel: homePageCarousel.carousel,
    promoBar: homePagePromoBar.promoBar,
    user: userInfo.user,
    welcomePromo: welcomePromo.welcomePromo,
    address: userAddress.address,
});

AppContainer.propTypes = {
    storeInfo: storeInfoPT,
    locationPhone: locationPhonePT,
    carousel: carouselPT,
    promoBar: promoBarPT,
    user: userInfoPT,
    address: addressPT,
    welcomePromo: welcomePromoPT,
    isBundleTheme: PropTypes.bool,
};

export default connect(mapStateToProps)(AppContainer);
